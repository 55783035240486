<template>
  <div>
    <div class="card">
      <div class="title van-hairline--bottom">
        <div class="name">时间段内销售汇总</div>
        <div class="handle" @click="startTimePopup = true">
          <div class="value" v-if="startTime"> {{ startTime }}</div>
          <div class="placehoder" v-else>选择开始时间</div>
          <i/>
          <div class="value" v-if="endTime"> {{ endTime }}</div>
          <div class="placehoder" v-else>选择结束时间</div>
          <img src="./../../assets/images/integral/arrow.png" alt="">
        </div>
      </div>

      <div class="content">
        <div class="top">
          <div class="key">销售总额（元）</div>
          <div class="value">{{incomeObj.totalIncome}}</div>
        </div>

        <div class="bottom">
          <div class="item ">
            <div class="key">销售总额（元）</div>
            <div class="value">{{incomeObj.totalIncome}}</div>
          </div>
          <div class="item van-hairline--right van-hairline--left">
            <div class="key">商品销售总额（元）</div>
            <div class="value">{{incomeObj.productIncome}}</div>
          </div>
          <div class="item">
            <div class="key">邮费销售总额（元）</div>
            <div class="value">{{incomeObj.postageIncome}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="title van-hairline--bottom">
        <div class="name">{{ month }}月销售汇总</div>
        <div class="handle" @click="monthPopup = true">
          <div class="value"> {{ year }}年</div>
          <img src="./../../assets/images/integral/arrow.png" alt="">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

          <div class="value"> {{ month }}月</div>
          <img src="./../../assets/images/integral/arrow.png" alt="">
        </div>
      </div>

      <div class="content">
        <div class="top">
          <div class="key">销售总额（元）</div>
          <div class="value">{{monthIncomeObj.totalIncome}}</div>
        </div>

        <div class="bottom">
          <div class="item ">
            <div class="key">订单数量（个）</div>
            <div class="value">{{monthIncomeObj.monthQuantity}}</div>
          </div>
          <div class="item van-hairline--right van-hairline--left">
            <div class="key">其中商品收入（元）</div>
            <div class="value">{{monthIncomeObj.productIncome}}</div>
          </div>
          <div class="item">
            <div class="key">其中邮费收入（元）</div>
            <div class="value">{{monthIncomeObj.postageIncome}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="title van-hairline--bottom">
        <div class="name">{{ year2 }}年销售汇总</div>
        <div class="handle" @click="yearPopup = true">
          <div class="value"> {{ year2 }}年</div>
          <img src="./../../assets/images/integral/arrow.png" alt="">
        </div>
      </div>

      <div class="content">
        <div class="top">
          <div class="key">销售总额（元）</div>
          <div class="value">{{yearIncomeObj.totalIncome}}</div>
        </div>

        <div class="bottom">
          <div class="item ">
            <div class="key">订单数量（个）</div>
            <div class="value">{{yearIncomeObj.yearQuantity}}</div>
          </div>
          <div class="item van-hairline--right van-hairline--left">
            <div class="key">其中商品收入（元）</div>
            <div class="value">{{yearIncomeObj.productIncome}}</div>
          </div>
          <div class="item">
            <div class="key">其中邮费收入（元）</div>
            <div class="value">{{yearIncomeObj.postageIncome}}</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="yearPopup" round position="bottom">
      <van-datetime-picker
          v-model="selectStartTime"
          class="year"
          type="year-month"
          title="选择年"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onYearConfirm"
      />
    </van-popup>
    <van-popup v-model="monthPopup" round position="bottom">
      <van-datetime-picker
          v-model="selectStartTime"
          type="year-month"
          title="选择年月"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onMonthConfirm"
      />
    </van-popup>
    <van-popup v-model="startTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectStartTime"
          type="date"
          title="选择开始时间"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onStartConfirm"
      />
    </van-popup>

    <van-popup v-model="endTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectEndTime"
          type="date"
          title="选择结束时间"
          :min-date="startTimeStack"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onEndConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from "vue";

export default {
  name: "TimeList",
  data() {
    return {
      startTimePopup: false,
      endTimePopup: false,
      monthPopup: false,
      yearPopup: false,
      maxDate: new Date(),
      startTimeStack: '',
      year2: '',
      year: '',
      month: '',
      startTime: '',
      endTime: '',
      selectStartTime: '',
      selectEndTime: '',
      incomeObj:{},//时间段内收入对象
      monthIncomeObj:{},//月度收入对象
      yearIncomeObj:{},//年度收入对象
    }
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },

    onMonthConfirm(val) {
      let date = new Date(val)
      this.month = moment(date).format('M')
      this.year = moment(date).format('YYYY')
      this.monthPopup = false
      this.getMonthIncome();
    },
    onYearConfirm(val) {
      let date = new Date(val)
      this.year2 = moment(date).format('YYYY')
      this.yearPopup = false
      this.getYearIncome();
    },

    onStartConfirm(val) {
      this.startTimeStack = val;
      this.startTimePopup = false;
      this.endTimePopup = true
    },
    onEndConfirm(val) {
      let date = new Date(this.startTimeStack)
      this.startTime = moment(date).format('YYYY-MM-DD')

      let endDate = new Date(val);
      this.endTime = moment(endDate).format('YYYY-MM-DD')
      this.endTimePopup = false
      this.getIncome();
    },
    //设置初始化日期
    setDate(){
      let date = new Date();
      this.selectEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      date.setDate(1);
      this.selectStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    getIncome(){
      //查询时间段内收入数据
      this.$http.get(`/mall/h5_statistics/totalincome`,{params:
            {deptId:Vue.prototype.deptId,startTime:this.startTime,endTime:this.endTime}},{
        emulateJSON:true
      }).then(res => {
        //时间段内收入数据
        this.incomeObj = res.data;
      });
    },
    //查询月度收入数据
    getMonthIncome(){
      //查询时间段内收入数据
      this.$http.get(`/mall/h5_statistics/monthincome`,{params:
            {deptId:Vue.prototype.deptId,month:this.year+"-"+(this.month<10?"0"+this.month:this.month)}},{
        emulateJSON:true
      }).then(res => {
        //月度收入对象
        this.monthIncomeObj = res.data;
      });
    },
    //查询年度收入数据
    getYearIncome(){
      //查询时间段内收入数据
      this.$http.get(`/mall/h5_statistics/yearincome`,{params:
            {deptId:Vue.prototype.deptId,year:this.year2}},{
        emulateJSON:true
      }).then(res => {
        //年度收入对象
        this.yearIncomeObj = res.data;
      });
    }

  },
  mounted() {
    this.setDate();
    let date = new Date();
    this.endTime = moment(date).format('YYYY-MM-DD')
    date.setDate(1);
    this.startTime = moment(date).format('YYYY-MM-DD')
    this.month = moment(date).format('M')
    this.year = moment(date).format('YYYY')
    this.year2 = moment(date).format('YYYY')
    this.getIncome();
    this.getMonthIncome();
    this.getYearIncome();
  }
}

</script>
<style lang="less" scoped>
.handle {
  display: flex;
  align-items: center;

  div {
    font-size: 10px;

    &.placehoder {
      color: #212121;
    }

    &.value {
      color: #212121;
    }
  }

  i {
    width: 10px;
    height: 1px;
    background-color: #212121;
    display: inline-block;
    margin: 0 5px;
  }

  img {
    height: 5px;
    width: 8px;
    margin-left: 7px;
  }
}


.card {
  background: #FFFFFF;
  border-radius: 8px;
  margin: 10px;

  padding: 0 18px;

  .title {
    height: 38px;
    display: flex;
    align-items: center;

    .name {
      font-size: 12px;
      font-weight: 500;
      color: #212121;
      margin-right: 26px;
    }
  }

  .content {
    .top {
      text-align: center;
      padding: 28px 0;

      .key {
        font-size: 11px;
        color: #666;
      }

      .value {
        font-size: 15px;
        color: #212121;
        font-weight: bold;
      }
    }

    .bottom {
      display: flex;
      padding-bottom: 22px;

      .item {
        height: 26px;
        flex: 1;
        text-align: center;

        .key {
          font-size: 10px;
          color: #666;
        }

        .value {
          font-size: 12px;
          color: #f00;
          font-weight: bold;
        }
      }
    }
  }
}
</style>

<style>
.year.van-picker .van-picker-column:nth-child(2) {
  display: none
}
</style>
