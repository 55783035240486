<template>
  <div style="padding-bottom: 1px;">
    <van-sticky>
      <div class="filter">
        <div class="item van-hairline--bottom">
          <div class="key">日期</div>
          <div class="value">
            <div class="handle" @click="startTimePopup = true">
              <div class="value" v-if="startTime"> {{ startTime }}</div>
              <div class="placehoder" v-else>选择开始时间</div>
              <i/>
              <div class="value" v-if="endTime"> {{ endTime }}</div>
              <div class="placehoder" v-else>选择结束时间</div>
              <img src="./../../assets/images/integral/arrow.png" alt="">
            </div>

          </div>
        </div>

        <div class="item">
          <div class="key">商品</div>
          <div class="value">
            <div class="handle value" @click="$refs.selectProduct.showPop()">
              <div class=" placehoder" v-if="!productName"> 选择商品</div>
              <div class="value" v-else> {{ productName }}</div>
              <img src="./../../assets/images/integral/arrow.png" alt="">
            </div>
          </div>
        </div>
      </div>

    </van-sticky>

    <van-list
        v-model="tableLoading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <div class="card"  v-for="(item,index) in dataList" :key="index" >
        <div class="title van-hairline--bottom">
          {{ item.productName }}
        </div>
        <div class="item van-hairline--bottom" v-for="(mx, temp) in item.productStatisticsVoList" @click="showDetail = true">
          <div class="num">{{ temp + 1 }}</div>
          <div class="right-content">
            <div class="name">{{ mx.grade }}</div>
            <div class="content">
              <van-row :gutter="20">
                <van-col :span="10">
                  <div class="key" style="color: #999999;">商品数量:</div>
                  <div class="value" style="color: #FF0000;">{{ mx.saleProductNum }}</div>
                </van-col>
                <van-col :span="10">
                  <div class="key" style="color: #999999;">总金额:</div>
                  <div class="value" style="color: #FF0000;">¥{{ mx.productPrice }}</div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </div>
    </van-list>


    <van-popup v-model="startTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectStartTime"
          type="date"
          title="选择开始时间"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onStartConfirm"
      />
    </van-popup>

    <van-popup v-model="endTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectEndTime"
          type="date"
          title="选择结束时间"
          :min-date="startTimeStack"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onEndConfirm"
      />
    </van-popup>

    <select-product ref="selectProduct" @select="onProductConfirm"/>

    <!-- 详情 -->
    <van-popup close-icon="close" closeable v-model="showDetail" round position="bottom">

      <div class="popupContent">
        <div class="title van-hairline--bottom">枫树山南屏小学夏装女款&nbsp;&nbsp;>&nbsp;&nbsp;一年级</div>
        <div class="list">
          <div class="item van-hairline--bottom" v-for="(item, index) in 10">
            <div class="num">{{ index + 1 }}</div>
            <div class="content">
              <div class="left">
                <div class="name">20210{{ index + 1 }}班</div>
                <div class="orderNum">
                  <div class="key">订单数:</div>
                  <div class="value">10000</div>
                </div>
              </div>

              <div class="right">
                <div class="key">总金额</div>
                <div class="value">¥10000</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import moment from 'moment';
import Vue from "vue";
import SelectProduct from "@/views/shelves/listComponents/selectGoodsWithSearch";

export default {
  name: "SchoolList",
  components:{SelectProduct},
  data() {
    return {
      showDetail: false,
      startTimePopup: false,
      endTimePopup: false,
      maxDate: new Date(),
      startTimeStack: '',

      startTime: '',
      endTime: '',
      selectStartTime: '',
      selectEndTime: '',
      tableLoading: false,
      finished: false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      dataList: [],
      productId: null,
      productName:"",
    }
  },

  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    onStartConfirm(val) {
      this.startTimeStack = val;
      this.startTimePopup = false;
      this.endTimePopup = true
    },
    onEndConfirm(val) {
      let date = new Date(this.startTimeStack)
      this.startTime = moment(date).format('YYYY-MM-DD')

      let endDate = new Date(val);
      this.endTime = moment(endDate).format('YYYY-MM-DD')
      this.endTimePopup = false
    },
    //设置初始化日期
    setDate() {
      let date = new Date();
      this.selectEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      date.setDate(1);
      this.selectStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    onLoad() {
      this.getSaleProducts(this.page, {startTime: this.startTime, endTime: this.endTime, productId: this.productId})
    },
    //销售商品分页数据
    getSaleProducts(page, params) {
      this.$http.get(`/mall/h5_statistics/gradeSalesDataByProductStatistics`, {
        params: Object.assign({
          current: page.currentPage,
          size: page.pageSize,
          deptId: Vue.prototype.deptId,
        }, params)
      }, {
        emulateJSON: true
      }).then(response => {
        if (page.currentPage == 1) {
          this.dataList = response.data.records
        } else {
          this.dataList = this.dataList.concat(response.data.records)
        }
        this.page.total = response.data.total
        if (this.dataList.length >= this.page.total) {
          this.finished = true;
        } else {
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    onProductConfirm(val){
      this.productName = val.name;
      this.productId = val.id;
      this.page.currentPage = 1;
      this.dataList = [];
      this.finished = false;
    }
  },

  mounted() {
    let date = new Date();
    this.endTime = moment(date).format('YYYY-MM-DD')
    date.setDate(1);
    this.startTime = moment(date).format('YYYY-MM-DD')

  }
}

</script>
<style lang="less" scoped>
.handle {
  display: flex;
  align-items: center;

  div {
    font-size: 12px;

    &.placehoder {
      color: #999;
    }

    &.value {
      color: #212121;
      width: 70px;
    }
  }


  i {
    width: 10px;
    height: 1px;
    background-color: #212121;
    display: inline-block;
    margin: 0 5px;
  }

  img {
    height: 5px;
    width: 8px;
    margin-left: 7px;
  }
}

.filter {
  background-color: #fff;

  .item {
    padding: 0 32px;
    height: 38px;
    display: flex;
    align-items: center;

    .key {
      font-size: 12px;
      color: #999999;
      margin-right: 10px;
    }

    .value {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        border: none;
        font-size: 12px;
        color: #212121;
        width: 80%;
      }
    }
  }
}

.card {
  background: #FFFFFF;
  border-radius: 8px;

  margin: 10px 18px;

  .title {
    padding: 0 18px;
    height: 34px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #212121;
  }


  .item {
    padding: 14px 18px;
    display: flex;
    align-items: center;

    .num {
      font-size: 16px;
      font-weight: 400;
      margin-right: 29px;
      color: #333333;
    }

    .right-content {
      flex: 1;
      width: 0;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #212121;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .content {
      .key {
        display: inline-block;
        font-size: 12px;
        color: #212121;
        line-height: 14px;
        margin-right: 3px;

      }

      .value {
        display: inline-block;
        font-size: 12px;
        color: #999;
        line-height: 14px;


      }
    }
  }
}


.popupContent {
  .title {
    height: 45px;
    line-height: 45px;

    font-size: 12px;
    font-weight: 500;
    color: #212121;
    padding: 0 18px;
  }

  .list {
    max-height: 70vh;
    overflow: auto;

    .item {
      display: flex;
      align-items: center;
      padding: 13px 32px 13px 39px;;

      .num {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 19px;

        .key, .value {
          font-size: 10px;
          line-height: 1;
        }

        .key {
          color: #999;
        }

        .value {
          color: #f00;
        }

        .left {
          .name {
            line-height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #212121;
            margin-bottom: 8px;
          }

          .orderNum {
            display: flex;
            align-items: center;

            .key {
              margin-right: 3px;
            }
          }
        }

        .right {
          .value {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 14px;
  font-size: 20px;
}
</style>
