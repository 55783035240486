<template>
  <div style="padding-bottom: 1px;">
    <van-sticky>
      <div class="filter">
        <div class="item van-hairline--bottom">
          <div class="key">日期</div>
          <div class="value">
            <div class="handle" @click="startTimePopup = true">
              <div class="value" v-if="startTime"> {{ startTime }}</div>
              <div class="placehoder" v-else>选择开始时间</div>
              <i/>
              <div class="value" v-if="endTime"> {{ endTime }}</div>
              <div class="placehoder" v-else>选择结束时间</div>
              <img src="./../../assets/images/integral/arrow.png" alt="">
            </div>


          </div>
        </div>

        <div class="item">
          <div class="key">学校</div>
          <div class="value">
            <div class="handle value" @click="$refs.selectSchool.showPop()">
              <div class=" placehoder" v-if="!schoolName"> 选择学校</div>
              <div class="value" v-else> {{ schoolName }}</div>
              <img src="./../../assets/images/integral/arrow.png" alt="">
            </div>
          </div>
        </div>
      </div>

    </van-sticky>

    <div class="card">
      <div class="title van-hairline--bottom" @click="showSortSheet = true">
        {{ sortName }}
        <van-icon name="arrow-down" style="margin-left: 3px"/>
      </div>
      <van-list
          v-model="tableLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div class="item van-hairline--bottom" v-for="(item,index) in dataList" :key="index" @click="schoolSaleDetailFun(item)">
          <div class="name">{{ item.schoolName }}</div>
          <div class="content">
            <van-row :gutter="2">
              <van-col :span="8">
                <div class="key" style="color: #999999;">订单数:</div>
                <div class="value" style="color: #FF0000;">{{item.orderNum}}</div>
              </van-col>
              <van-col :span="8">
                <div class="key">共计商品:</div>
                <div class="value">{{item.goodsNum}}</div>
              </van-col>

              <van-col :span="8">
                <div class="key">邮费金额:</div>
                <div class="value">{{item.postageTotal}}</div>
              </van-col>

              <van-col :span="8">
                <div class="key" style="color: #999999;">总金额:</div>
                <div class="value" style="color: #FF0000;">{{ item.saleTotal }}</div>
              </van-col>
              <van-col :span="8">
                <div class="key">在售商品:</div>
                <div class="value">{{ item.saleGoodsNum }}</div>
              </van-col>
              <van-col :span="8">
                <div class="key">下架商品:</div>
                <div class="value">{{ item.offSaleGoodsNum }}</div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-list>
    </div>


    <select-school ref="selectSchool" @select="onSchoolConfirm"/>

    <van-popup v-model="startTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectStartTime"
          type="date"
          title="选择开始时间"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onStartConfirm"
      />
    </van-popup>

    <van-popup v-model="endTimePopup" round position="bottom">
      <van-datetime-picker
          v-model="selectEndTime"
          type="date"
          title="选择结束时间"
          :min-date="startTimeStack"
          :max-date="maxDate"
          :formatter="formatter"
          @confirm="onEndConfirm"
      />
    </van-popup>

    <!-- 选择排序 -->
    <van-action-sheet
        v-model="showSortSheet"
        :actions="actions"
        @select="onSelect"
    />


    <!-- 详情 -->
    <van-popup
        style="min-height: 60vh;"
        close-icon="close"
        closeable
        v-model="showDetail"
        round
        position="bottom">

      <div class="popupContent">
        <div class="title van-hairline--bottom">{{ thisSchool.schoolName }}</div>
        <div class="list">
          <div class="item van-hairline--bottom" v-for="(item, index) in schoolSaleDetail" :key="index">
            <div class="num">{{ index + 1 }}</div>
            <div class="content">
              <div class="left">
                <div class="name">{{item.grade}}</div>
                <div class="orderNum">
                  <div class="key">订单数:</div>
                  <div class="value">{{item.orderNum}}</div>
                </div>
              </div>

              <div class="right">
                <div class="key">总金额</div>
                <div class="value">¥{{item.saleTotal}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>
import moment from 'moment';
import SelectSchool from "@/views/shelves/listComponents/selectSchoolWihtSearch";
import Vue from "vue";

export default {
  name: "SchoolList",
  components: {SelectSchool},
  data() {
    return {
      showDetail: false,
      showSortSheet: false,
      startTimePopup: false,
      endTimePopup: false,
      schoolPopup: false,
      maxDate: new Date(),
      startTimeStack: '',
      startTime: '',
      endTime: '',
      schoolName: '',
      sortName: '综合排序',
      selectStartTime: '',
      selectEndTime: '',
      sort:'',
      tableLoading:false,
      finished:false,
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20 // 每页显示多少条
      },
      dataList:[],
      //学校销售详情
      schoolSaleDetail:[],
      thisSchool:{},
    }
  },
  computed: {
    actions() {
      let arr = [
        {name: '综合排序', value: 1},
        {name: '订单数降序', value: 2},
        {name: '订单数升序', value: 3},
        {name: '总额降序', value: 4},
        {name: '总额升序', value: 5}
      ]

      arr.forEach(e => {
        if (this.sortName === e.name) {
          e.color = '#D53A26'
        }
      })
      return arr
    }
  },
  methods: {
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },

    onSelect(action) {
      this.sortName = action.name;
      this.sort = action.value;
      this.showSortSheet = false
      this.page.currentPage = 1;
      this.dataList = [];
      this.finished = false;
    },


    onSchoolConfirm(val) {
      this.schoolName = val.name;
      this.page.currentPage = 1;
      this.dataList = [];
      this.finished = false;
    },

    onStartConfirm(val) {
      this.startTimeStack = val;
      this.startTimePopup = false;
      this.endTimePopup = true
    },
    onEndConfirm(val) {
      let date = new Date(this.startTimeStack)
      this.startTime = moment(date).format('YYYY-MM-DD')

      let endDate = new Date(val);
      this.endTime = moment(endDate).format('YYYY-MM-DD')
      this.endTimePopup = false
    },
    //设置初始化日期
    setDate(){
      let date = new Date();
      this.selectEndTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      date.setDate(1);
      this.selectStartTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    onLoad() {
      this.getSaleProducts(this.page,{school:this.schoolName,sort:this.sort,startTime:this.startTime,endTime:this.endTime})
    },
    //销售商品分页数据
    getSaleProducts(page,params) {
      this.$http.get(`/mall/h5_statistics/schoolsalePage`,{params:Object.assign({
          current: page.currentPage,
          size: page.pageSize,
          deptId:Vue.prototype.deptId,
        }, params)},{
        emulateJSON:true
      }).then(response => {
        if(page.currentPage == 1){
          this.dataList = response.data.records
        }else{
          this.dataList = this.dataList.concat(response.data.records)
        }
        this.page.total = response.data.total
        if(this.dataList.length >= this.page.total){
          this.finished = true;
        }else{
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
      })
    },
    schoolSaleDetailFun(item){
      this.thisSchool = item;
      this.showDetail = true;
      //查询学校销售详情数据
      this.$http.get(`/mall/h5_statistics/schoolsaleDetail`,
          {params:{  deptId:Vue.prototype.deptId,school:item.schoolName,startTime:this.startTime,endTime:this.endTime}}
          ,{  emulateJSON:true}).then(response => {
            this.schoolSaleDetail = response.data;
      })
    }
  },

  mounted() {
    this.setDate();
    let date = new Date();
    this.endTime = moment(date).format('YYYY-MM-DD')
    date.setDate(1);
    this.startTime = moment(date).format('YYYY-MM-DD')

  }
}

</script>
<style lang="less" scoped>
.handle {
  display: flex;
  align-items: center;

  div {
    font-size: 12px;

    &.placehoder {
      color: #999;
    }

    &.value {
      color: #212121;
      width: 70px;
    }
  }

  i {
    width: 10px;
    height: 1px;
    background-color: #212121;
    display: inline-block;
    margin: 0 5px;
  }

  img {
    height: 5px;
    width: 8px;
    margin-left: 7px;
  }
}

.filter {
  background-color: #fff;

  .item {
    padding: 0 32px;
    height: 38px;
    display: flex;
    align-items: center;

    .key {
      font-size: 12px;
      color: #999999;
      margin-right: 10px;
    }

    .value {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.card {
  background: #FFFFFF;
  border-radius: 8px;

  margin: 10px 18px;

  .title {
    padding: 0 18px;
    height: 34px;
    display: flex;
    align-items: center;

    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #D61619;
  }


  .item {
    padding: 19px 18px;

    &:last-child {
      &::after {
        display: none;
      }
    }

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #212121;
      line-height: 14px;
      margin-bottom: 10px;
    }

    .content {
      .key {
        display: inline-block;
        font-size: 12px;
        color: #212121;
        line-height: 14px;
        margin-right: 3px;

      }

      .value {
        display: inline-block;
        font-size: 12px;
        color: #999;
        line-height: 14px;
      }
    }
  }
}


.popupContent {
  .title {
    height: 45px;
    line-height: 45px;

    font-size: 14px;
    font-weight: 500;
    color: #212121;
    padding: 0 18px;
  }

  .list {
    max-height: 70vh;
    overflow: auto;

    .item {
      display: flex;
      align-items: center;
      padding: 13px 32px 13px 39px;;

      .num {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 15px;
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 19px;

        .key, .value {
          font-size: 10px;
          line-height: 1;
        }

        .key {
          color: #999;
        }

        .value {
          color: #f00;
        }

        .left {
          .name {
            line-height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #212121;
            margin-bottom: 8px;
          }

          .orderNum {
            display: flex;
            align-items: center;

            .key {
              margin-right: 3px;
            }
          }
        }

        .right {
          .value {
            margin-top: 5px;
          }
        }
      }
    }
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 14px;
  font-size: 20px;
}
</style>
