<template>
    <div class="page-gray">
        <van-tabs
            v-model="active"
            sticky
            swipeable
            :line-width="15"
            :line-height="4">
            <van-tab title="按时间" name="0">
                <time-list />
            </van-tab>
            <van-tab title="按学校" name="1">
                <school-list />
            </van-tab>
            <van-tab title="按商品" name="2">
                <goods-list />
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import GoodsList from './goodsList.vue'
import SchoolList from './schoolList.vue'
import timeList from './timeList.vue'
export default {
  components: { timeList, SchoolList, GoodsList },
    name: "Sales",

    data() {
        return {
            active: '0'
        }
    }
}

</script>
<style lang="less" scoped>

</style>
